/** @jsx jsx */
import * as React from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import tw, { styled } from "twin.macro"
import { jsx, css } from '@emotion/react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faReact } from "@fortawesome/free-brands-svg-icons"
import { faArrowRight, faAsterisk } from "@fortawesome/free-solid-svg-icons"
import { Formik, useFormik } from "formik"
import * as Yup from "yup";
// import { useSpring, animated } from 'react-spring'
import {useState} from "react"



const validate = values => {
  const errors = {};
  if (values.email === null ) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  return errors;
};

const fieldStyles = " w-60 rounded-none border border-black focus:rounded-none focus:outline-none focus:ring focus:border-blue-300 mb-4 p-2"



const NewEvalForm = () => {


  let emailCheck


      if (typeof window !== "undefined"){

          if (document.referrer.indexOf(window.location.hostname) !== -1) {
          console.log("history undefined")}

        else {
          if (typeof (JSON.stringify(window.history.state?.submittedEmail)) !== "undefined") {
            (emailCheck = JSON.parse(JSON.stringify(window.history.state.submittedEmail.email)))
          }

          // if (emailCheck) {
          //   initialValues = {
          //     "name": "email",
          //     "value": emailCheck,
          //   }
          // }
        }
      }

  return (
    <Formik
      initialValues={{  email: emailCheck}}

      onSubmit={async (values,  onsubmitProps, setStatus) => {
        await new Promise(resolve => setTimeout(resolve, 500))
        // alert(JSON.stringify(values, null, 2));
        const completedFormValues = values
        console.log(JSON.stringify(values, null, 2))
        const url = 'https://api.hsforms.com/submissions/v3/integration/submit/19556060/2dcf5d1b-9cf6-4111-a442-c676b3bcc5ee'
        const xhr = new XMLHttpRequest();

        const dataToSendToHubspot = {
          "fields": [
            {
              "name": "website",
              "value": values.website
            },
            {
              "name": "email",
              "value": values.email
            },
            {
              "name": "phone",
              "value": values.phone
            },
            {
              "name": "firstname",
              "value": values.firstName
            },
            {
              "name": "lastname",
              "value": values.lastName
            }
          ],
          "context": {
            "pageUri": "www.zycada.com/free-site-evaluation",
            "pageName": "Free Site Evaluation"
          },
        }

        const final_data = (JSON.stringify(dataToSendToHubspot, null, 2))
        console.log(final_data)
        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'application/json');
        // xhr.onreadystatechange = function () {
        //   if (xhr.readyState === 4 && xhr.status === 200) {
        //     alert(xhr.responseText); // Returns a 200 response if the submission is successful.
        //   } else if (xhr.readyState === 4 && xhr.status === 403) {
        //     alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
        //   } else if (xhr.readyState === 4 && xhr.status === 404) {
        //     alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found
        //   }
        // }
// Sends the request
        xhr.send(final_data)

        navigate("/thankyou",
          {
            state: { completedFormValues }
          })
      }}
      enableReinitialize

      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Please enter a valid email.")
          .required("Email is required.")
          // .test("isPreFilledEmail", "Email is required", value => {
          //     if (!value) {
          //       return false
          //     }
          //   }
          // )
          .test(
            "Please use your work email.",
            "Please use your work email.",
            (value) => !containsFreeEmail(value)),
        firstName: Yup.string()
          .required("First Name required."),
        lastName: Yup.string()
          .required("Last Name required."),
        phone: Yup.string(),
        // .required("Phone number required."),
        website: Yup.string()
          .required("Website URL required.")


      })}
    >{props => {
      const {
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset
      } = props
      return (
        <form
          onSubmit={handleSubmit}
          className={""}
        >
          <label htmlFor="email" style={{ display: "block" }}>
          </label>
          <div className={"flex flex-col ml-4"}>
            <div>
              <input
                name={"website"}
                id="website"
                placeholder="Website..."
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                className={fieldStyles
                  // errors.email && touched.email
                  //   ? "text-input error"
                  //   : "text-input"
                }
              />
              &nbsp;<FontAwesomeIcon icon={faAsterisk} className={"absolute text-orange"} size={"xs"}/>
            </div>
            <div>

              <input
                name={"email"}
                id="email"
                placeholder="Work email..."
                type="text"
                defaultValue={emailCheck}
                onChange={handleChange}
                onBlur={handleBlur}
                className={fieldStyles}
              />
              &nbsp;<FontAwesomeIcon icon={faAsterisk} className={"absolute text-orange"} size={"xs"}/>
            </div>
            <div>
              <input
                id="phone"
                placeholder="Phone..."
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                className={fieldStyles}
              />
            </div>
            <div>
              <input
                id="firstName"
                placeholder="First Name..."
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                className={fieldStyles}
              />
              &nbsp;<FontAwesomeIcon icon={faAsterisk} className={"absolute text-orange"} size={"xs"}/>
            </div>
            <div>
              <input
                id="lastName"
                placeholder="Last Name..."
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                className={fieldStyles}
              />
              &nbsp;<FontAwesomeIcon icon={faAsterisk} className={"absolute text-orange"} size={"xs"}/>
            </div>
            <div className={"flex justify-center"}>
              <Button
                type="submit"
                className={"w-4/5"}
                disabled={isSubmitting}
              >
                Get Started &nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight}/>&nbsp;&nbsp;</Button>
            </div>
          </div>
          <div className={"flex flex-col"}>
            {errors.email && touched.email && (
              <div className="text-center"><br/><p className={"text-orange"}>{errors.email}</p></div>
            )}
            {errors.website && touched.website && (
              <div className="text-center"><br/><p className={"text-orange"}>{errors.website}</p></div>
            )}
            {errors.phone && touched.phone && (
              <div className="text-center"><br/><p className={"text-orange"}>{errors.phone}</p></div>
            )}
            {errors.firstName && touched.firstName && (
              <div className="text-center"><br/><p className={"text-orange"}>{errors.firstName}</p></div>
            )}
            {errors.lastName && touched.lastName && (
              <div className="text-center"><br/><p className={"text-orange"}>{errors.lastName}</p></div>
            )}
          </div>
        </form>
      )
    }}
    </Formik>)
}

// const EvalForm = () => {
//   // Pass the useFormik() hook initial form values and a submit function that will
//   // be called when the form is submitted
//   let parsedEmail
//   let preloadedEmail
//   let emailCheck
//
//
//   if (typeof window !== "undefined" ) {
//     if (typeof (JSON.stringify(window.history.state.submittedEmail)) !== "undefined") {
//       (emailCheck = JSON.parse(JSON.stringify(window.history.state.submittedEmail.email)))
//     }
//     preloadedEmail = emailCheck
//     if (preloadedEmail) {
//       parsedEmail = preloadedEmail
//     }
//   }
//
// // .substring(1, preloadedEmail.length-1);
//
//
//   const formik = useFormik({
//     initialValues: {
//       email: '',
//     },
//     validate,
//     onSubmit: values => {
//       console.log(JSON.stringify(values, null, 2));
//       // alert(JSON.stringify(values, null, 2));
//       navigate("/free-site-evaluation/");
//     },
//   });
//   return (
//     <form
//       onSubmit={formik.handleSubmit}
//       className={"flex flex-col justify-center"}
//     >
//       <label htmlFor="website"></label>
//       <input
//         placeholder={"Website..."}
//
//         className="
//         
//         w-60
//         rounded-none
//         border
//         border-black
//         focus:rounded-none
//         focus:outline-none
//         focus:ring
//         focus:border-blue-300
//         mb-4
//         p-2
//         "
//         id="website"
//         name="website"
//         type="website"
//         onChange={formik.handleChange}
//       />
//       <input
//         placeholder={"Work email..."}
//
//         className="
//         
//         w-60
//         rounded-none
//         border
//         border-black
//         focus:rounded-none
//         focus:outline-none
//         focus:ring
//         focus:border-blue-300
//         mb-4
//         p-2
//         "
//         id="email"
//         name="email"
//         type="email"
//         onChange={formik.handleChange}
//         value={parsedEmail}
//       />
//
//       <input
//         placeholder={"Phone..."}
//
//         className="
//         
//         w-60
//         rounded-none
//         border
//         border-black
//         focus:rounded-none
//         focus:outline-none
//         focus:ring
//         focus:border-blue-300
//         mb-4
//         p-2
//         "
//         id="phone"
//         name="phone"
//         type="phone"
//         onChange={formik.handleChange}
//       />
//
//       <input
//         placeholder={"First name..."}
//
//         className="
//         
//         w-60
//         rounded-none
//         border
//         border-black
//         focus:rounded-none
//         focus:outline-none
//         focus:ring
//         focus:border-blue-300
//         mb-4
//         p-2
//         "
//         id="firstName"
//         name="firstName"
//         type="firstName"
//         onChange={formik.handleChange}
//       />
//
//       <input
//         placeholder={"Last name..."}
//
//         className="
//         
//         w-60
//         rounded-none
//         border
//         border-black
//         focus:rounded-none
//         focus:outline-none
//         focus:ring
//         focus:border-blue-300
//         mb-4
//         p-2
//         "
//         id="lastName"
//         name="lastName"
//         type="lastName"
//         onChange={formik.handleChange}
//       />
//
//       <Button
//         type="submit"
//         >
//         Get Started &nbsp;&nbsp; <FontAwesomeIcon icon={faArrowRight} />&nbsp;&nbsp;</Button>
//       </form>
//   );
// };


const containsFreeEmail = (string) => /(hotmail.com)|(gmail.com)|(outlook.com)|(yahoo.com)|(aol.com)|(protonmail.com)/.test(string)


const FreeSiteEvaluation = () => (
  <Layout>
    <SEO title="" />

   
    <div className="flex justify-center p-4">
      <div className={"flex flex-col"}>

      <div className="flex flex-col">
        <h4 className={"text-orange uppercase text-center"}>Ready to see Zycada in Action?</h4>
        <h1 className={"text-center"}>Free Site Evaluation</h1>
        <p className={"text-center"}>We'll do a custom site evaluation for you at no charge. Send us your information and we'll contact you shortly.</p>
        <div className="flex justify-center min-h-screen items-start">
        <div className={"hidden w-3/5 px-12 md:block"}>
          <p className={"text-center"}>Join the industry leaders and boutique brands accelerating their customer experience with Zycada.</p>

          <div className={"grid grid-cols-4 grid-rows-1 gap-10 justify-items-stretch p-4"}>
            <StaticImage
              src="../images/harmanLogo300.png"
              quality={95}
              height={100}
              objectFit={"contain"}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Harman logo"
              styles={{}}
              loading={"eager"}
              placeholder={"none"}/>

            <StaticImage
              src="../images/jensHansenLogo300.png"
              quality={95}
              height={100}
              objectFit={"contain"}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Jens Hansen logo"
              styles={{}}
              loading={"eager"}
              placeholder={"none"}/>

            <StaticImage
              src="../images/brotherLogo300.png"
              quality={95}
              height={100}
              objectFit={"contain"}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Brother logo"
              styles={{}}
              loading={"eager"}
              placeholder={"none"}/>

            <StaticImage
              src="../images/livingSpacesLogo300.png"
              quality={95}
              height={100}
              objectFit={"contain"}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Living Spaces logo"
              styles={{}}
              loading={"eager"}
              placeholder={"none"}/>
          </div>
          <div className={"grid grid-cols-4 grid-rows-1 gap-10 justify-items-stretch p-4"}>

          <StaticImage
              src="../images/redBullLogo300.png"
              quality={95}
              height={100}
              objectFit={"contain"}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Red Bull logo"
              styles={{}}
              loading={"eager"}
              placeholder={"none"}/>

            <StaticImage
              src="../images/danesonLogo300.png"
              quality={95}
              height={100}
              objectFit={"contain"}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Daneson logo"
              styles={{}}
              loading={"eager"}
              placeholder={"none"}/>

            <StaticImage
              src="../images/hoboBagsLogo300.png"
              quality={95}
              height={100}
              objectFit={"contain"}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Hobo Bags logo"
              styles={{}}
              loading={"eager"}
              placeholder={"none"}/>

            <StaticImage
              src="../images/aurisLogo300.png"
              quality={95}
              height={100}
              objectFit={"contain"}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Auris logo"
              styles={{}}
              loading={"eager"}
              placeholder={"none"}/>

          </div>
        </div>
        <div>
        <NewEvalForm></NewEvalForm>
        </div>
        </div>
      </div>
      </div>

    </div>



  </Layout>
)

export default FreeSiteEvaluation
